// Footer.js (React + JSX)

import React from "react";
import "./Footer.css";
import Logo from "../Images/logo.jpeg"; // Update this path to the correct one
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        {/* Logo and description */}
        <div className="footer__section footer__brand">
          <img
            src={Logo} // Replace with your actual logo path
            alt="Company Logo"
            className="footer__logo"
          />
          {/* <p className="footer__description">
            Lorem ipsum dolor sit amet consectetur 
          </p> */}
          <div className="footer__social-icons">
            {/* Replace # with actual links */}
            <a href="#" className="footer__social-link">
              <i className="fab fa-facebook"></i>
            </a>

            <a href="#" className="footer__social-link">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="footer__social-link">
              <i className="fab fa-linkedin"></i>
            </a>

          </div>
        </div>

        {/* Product links */}
        <div className="footer__section">
          <h4 className="footer__title">Product</h4>
          <ul className="footer__list">
            <li className="footer__list-item">Features</li>
            <li className="footer__list-item">Pricing</li>
            <li className="footer__list-item">Case studies</li>
            <li className="footer__list-item">Reviews</li>
            <li className="footer__list-item">Updates</li>
          </ul>
        </div>

        {/* Company links */}
        <div className="footer__section">
          <h4 className="footer__title">Company</h4>
          <ul className="footer__list">
            <li className="footer__list-item">About</li>
            <li className="footer__list-item">Contact us</li>
            <li className="footer__list-item">Careers</li>
            <li className="footer__list-item">Culture</li>
            <li className="footer__list-item">Blog</li>
          </ul>
        </div>

        {/* Support links */}
        <div className="footer__section">
          <h4 className="footer__title">Support</h4>
          <ul className="footer__list">
            <li className="footer__list-item">Getting started</li>
            <li className="footer__list-item">Help center</li>
            <li className="footer__list-item">Server status</li>
            <li className="footer__list-item">Report a bug</li>
            <li className="footer__list-item">Chat support</li>
          </ul>
        </div>

        {/* Downloads links */}
        <div className="footer__section">
          <h4 className="footer__title">Downloads</h4>
          <ul className="footer__list">
            <li className="footer__list-item">iOS</li>
            <li className="footer__list-item">Android</li>
            <li className="footer__list-item">Mac</li>
            <li className="footer__list-item">Windows</li>
            <li className="footer__list-item">Chrome</li>
          </ul>
        </div>
      </div>

      <div className="footer__bottom">
        <p className="footer__copyright">
          Copyright © 2024 Michel International | All Rights Reserved | <a href="#">Terms and Conditions</a> | <a href="#">Privacy Policy</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
