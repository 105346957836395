import React from "react";
import "./AboutUs.css";
import backgroundImage from '../Images/about.svg'; // Update this path to the correct one

const AboutUs = () => {
  return (
    <section className="about-us">
      {/* Section Title */}
      <h1 className="about-us__main-title">About Us</h1>

      <div className="about-us__container">
        {/* Image Section */}
        <div className="about-us__image-container">
          <img src={backgroundImage} alt="About Us" className="about-us__image" />
        </div>

        {/* Content Section */}
        <div className="about-us__content">
          <h6 className="about-us__title">
            Pioneers in Software Development and Digital Transformation
          </h6>
          <p className="about-us__description">
            At Michel International, we are passionate about technology and its potential to change the world.
            Founded with a vision to deliver excellence in software development, we have grown into a
            multifaceted company specializing in cutting-edge solutions for diverse industries. Our team of
            dedicated professionals combines creativity, technical expertise, and strategic thinking to build
            solutions that empower our clients to achieve their goals.
          </p>
          <div className="about-us__mission-vision">
            <div className="about-us__mission">
              <h3>Our Mission</h3>
              <p>
                To innovate and deliver robust, scalable, and impactful digital solutions that drive success
                for our clients worldwide.
              </p>
            </div>
            <div className="about-us__vision">
              <h3>Our Vision</h3>
              <p>
                To be a global leader in software development and digital transformation, recognized for our
                quality, integrity, and commitment to excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
