// import React from "react";
// import "./NavBar.css";
// import Logo from "../Images/logo.svg";

// const NavBar = () => {
//   return (
//     <header className="navbar">
//       <div className="navbar__logo">
//         <img
//           src={Logo}
//           alt="Michael International Logo"
//           className="navbar__logo-image"
//         />

//       </div>
//       <nav className="navbar__menu">
//         <ul className="navbar__menu-list">
//           <li className="navbar__menu-item">Home</li>
//           <li className="navbar__menu-item">About</li>
//           <li className="navbar__menu-item">
//             Products
//             <ul className="navbar__submenu">
//               <li className="navbar__submenu-item">Product 1</li>
//               <li className="navbar__submenu-item">Product 2</li>
//             </ul>
//           </li>
//           <li className="navbar__menu-item">Pricing</li>
//           <li className="navbar__menu-item">FAQ</li>
//         </ul>
//       </nav>
//       <div className="navbar__auth">
//         <a href="/login" className="navbar__auth-login">
//           Login
//         </a>
//         <button className="navbar__auth-signup">Signup</button>
//       </div>
//     </header>
//   );
// };

// export default NavBar;

import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../Images/logo.jpeg";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="navbar">
      {/* Logo */}
      <div className="navbar__logo">
        <img src={Logo} alt="Michael International Logo" className="navbar__logo-image" />
      </div>

      {/* Hamburger Menu Icon */}
      <button className="navbar__toggle" onClick={handleToggle}>
        &#9776;
      </button>

      {/* Navigation Menu */}
      <nav className={`navbar__menu ${isMobileMenuOpen ? "active" : ""}`}>
        <ul className="navbar__menu-list">
          <li className="navbar__menu-item">Home</li>
          <li className="navbar__menu-item">About</li>
          <li className="navbar__menu-item">
            Products
            <ul className="navbar__submenu">
              <li className="navbar__submenu-item">Product 1</li>
              <li className="navbar__submenu-item">Product 2</li>
            </ul>
          </li>
          <li className="navbar__menu-item">Pricing</li>
          <li className="navbar__menu-item">FAQ</li>
        </ul>
      </nav>

      {/* Auth Section */}
      <div className="navbar__auth">
        <a href="/login" className="navbar__auth-login">Login</a>
        <button className="navbar__auth-signup">Signup</button>
      </div>
    </header>
  );
};

export default NavBar;
