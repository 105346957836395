import React from "react";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-section__content">
        <h1 className="hero-section__title">
          Transforming Ideas <br></br>into Digital Reality
        </h1>
        <p className="hero-section__subtitle">
          Michel International is at the forefront of software innovation, crafting<br></br>
          bespoke digital solutions that drive growth, efficiency, and impact.
        </p>
        <div className="hero-section__buttons">
          <button className="hero-section__button hero-section__button--primary">
            Get started
          </button>
          <button className="hero-section__button hero-section__button--secondary">
            Talk to sales
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
